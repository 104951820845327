import React from "react";
import styled from "styled-components";
import SEO from "../components/seo";
import breakpoints from "../constants/breakpoints";
import RafPortrait from "../images/about/raf.jpg";
import BrianPortrait from "../images/about/bri.jpg";
import TagorePortrait from "../images/about/tagore.jpg";
import Layout from "../components/layout";
import { siteColors } from "../constants/colors";
import { ExternalLink } from "../components/sitelinks";


const MainContainer = styled.div`
width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
   font-size: 1.2em;
`;

const BodyContainer = styled.div`
margin-top: 3em;
width: 80%;
max-width: 1024px;
   color:  ${ siteColors.light };
  padding-bottom: 3em;
  @media (max-width: ${ breakpoints.desktopSmallHD }px) {
    padding-bottom: 2em;
  }
  @media (max-width: ${ breakpoints.desktopSD }px) {
    margin-top: 2em;
    margin-bottom: 1em;
  }
  @media (max-width: ${ breakpoints.desktopSD }px) {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  @media (max-width: ${ breakpoints.xLargePhone }px) {
    width: 90%
  }
`;

const HeaderContainer = styled.div`
  width: 100%
  display: flex;
  color:  ${ siteColors.light };
font-size 3em;
justify-contents: center;
align-items: center;
margin-top: 1em;
  @media (max-width: ${ breakpoints.desktopSD }px) {
    font-size: 2.4em;
  }
  @media (max-width: ${ breakpoints.iPad }px) {
    font-size: 2em;
  }
  @media (max-width: ${ breakpoints.xLargePhone }px) {
    font-size: 1.6em;
  }
  @media (max-width: ${ breakpoints.mediumPhone }px) {
    font-size: 1.4em;
  }
`;

const PortraitsContainer = styled.div`
   width: 80%;
max-width: 1024px;
   display: flex;
justify-content: space-between;
  @media (max-width: ${ breakpoints.desktopSmallHD }px) {
    flex-direction: column;
justify-content: center;
  }
`;

const PortraitContainer = styled.div`
   width: 28%;
  color:  ${ siteColors.light };
  @media (max-width: ${ breakpoints.desktopSmallHD }px) {
    width: 100%;
    margin-top: 2em;
  }
`;

const PortraitBody = styled.div`
   display: flex;
   flex-direction: column;
  @media (max-width: ${ breakpoints.desktopSmallHD }px) {
   flex-direction: row;
  }
  @media (max-width: ${ breakpoints.iPad }px) {
   flex-direction: column;
margin-bottom: 2em;
  }
`;

const PortraitImageContainer = styled.div`
   margin-bottom: 1.4em;
display: flex;
justify-content: center;
`;

const PortraitImageInnerContainer = styled.div`
  position: relative;
  width: 85%; /* The size you want */
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%; /* The padding depends on the width, not on the height, so with a padding-bottom of 100% you will get a square */
  }
  @media (max-width: ${ breakpoints.desktopSmallHD }px) {
   width: 243px;
  }
  @media (max-width: ${ breakpoints.iPad }px) {
  width: 85%; /* The size you want */
max-width: 320px;
  }
`;

const PortraitImage = styled.img`
   height: auto;
   border-radius: 50%;
padding: 0;
margin: 0;
  position: absolute; /* Take your picture out of the flow */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; /* Make the picture taking the size of it's parent */
  width: 100%; /* This if for the object-fit */
  height: 100%; /* This if for the object-fit */
  object-fit: cover; /* Equivalent of the background-size: cover; of a background-image */
  object-position: center;
`;

const PortraitName = styled.div`
  font-size 1.4em;
  text-align: center;
  margin-bottom: 0.5em;
  @media (max-width: ${ breakpoints.desktopSmallHD }px) {
    text-align: left;
  }
`;

const PortraitBio = styled.div`
  @media (max-width: ${ breakpoints.desktopSmallHD }px) {
    margin-top: 0em;
    padding-left: 2.6em;
  }
  @media (max-width: ${ breakpoints.iPad }px) {
    padding-left: 0;
  }
`;

const AboutPage = () => (
        <Layout>
        <SEO title= "About" />
        <MainContainer>
   <HeaderContainer>About Notional Pipe</HeaderContainer>
       <BodyContainer>
        <p>
        CG character animation tools haven’t changed that much since the late 1990s. Sure, there have been incremental improvements in rigging technology, but by and large animation tools are still clumsy, awkward, and slow.
        </p>

        <p>
        At Notional Pipe, we believe that animation tools can, and should, be better. We’re dedicated to creating tools that make animation production faster, easier, and more expressive for animators&mdash;all of which translates into a higher quality product and happier artists. Solving the big problems of animation production starts with rigging, so our first product, Contour Rig Tools, introduces an innovative spline-based rigging system.
        </p>
        </BodyContainer>
        <PortraitsContainer>
          <PortraitContainer>
        <PortraitBody>
        <PortraitImageContainer>
        <PortraitImageInnerContainer>
        <PortraitImage src={RafPortrait} alt="Raf Anzovin"/>
        </PortraitImageInnerContainer>
        </PortraitImageContainer>
        <PortraitBio>
        <PortraitName>
        Raf Anzovin
    </PortraitName>
        Raf Anzovin's extensive rigging and animation credits include work for clients such as EA, ReelFX, and Lego, and the well-known auto-rigging tool The Setup Machine. He investigates new animation methodologies at <ExternalLink href="http://www.justtodosomethingbad.com" target="_blank">Just To Do Something Bad</ExternalLink>
        </PortraitBio>
        </PortraitBody>
          </PortraitContainer>

        <PortraitContainer>
        <PortraitBody>
        <PortraitImageContainer>
        <PortraitImageInnerContainer>
        <PortraitImage src={TagorePortrait} alt="Tagore Smith"/>
        </PortraitImageInnerContainer>
        </PortraitImageContainer>
        <PortraitBio>
        <PortraitName>
        Tagore Smith
    </PortraitName>
        Tagore Smith has over two decades experience developing software across a broad range of domains, with a particular emphasis on devising novel algorithms for computer graphics and animation.
    </PortraitBio>
        </PortraitBody>
        </PortraitContainer>

        <PortraitContainer>
        <PortraitBody>
        <PortraitImageContainer>
        <PortraitImageInnerContainer>
        <PortraitImage src={BrianPortrait} alt="Brian Kendall" />
        </PortraitImageInnerContainer>
        </PortraitImageContainer>
        <PortraitBio>
        <PortraitName>
        Brian Kendall
    </PortraitName>
Brian Kendall has been tinkering with computers his entire life, driven to make them do new and interesting things, and make the things they already do smarter and easier. He's worked for over a decade developing animation software to this end.
        </PortraitBio>
        </PortraitBody>
        </PortraitContainer>
        </PortraitsContainer>

        </MainContainer>
        </Layout>
);

export default AboutPage;
