import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { siteColors } from "../constants/colors";

const ExternalLink = styled.a`
  color: ${ siteColors.lightAccent };
  text-decoration: None;  &:hover {
    color: ${ siteColors.darkAccent };
  }
`;

const EmailLink = ExternalLink;

const InternalLink = styled(Link)`
  color: ${ siteColors.lightAccent };
  text-decoration: None;  &:hover {
    color: ${ siteColors.darkAccent };
  }
`;

export { ExternalLink, EmailLink, InternalLink };
